var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"card-toolbar mb-2 mr-2"},[_c('button',{staticClass:"btn btn-light-primary font-weight-bold px-10",on:{"click":_vm.toggleAdvancedView}},[_vm._v(" "+_vm._s(_vm.isAdvancedView ? _vm.$t('GENERAL.SIMPLE_VIEW') : _vm.$t('GENERAL.ADVANCED_VIEW'))+" ")])])]),_c('div',{staticClass:"mb-4 pb-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-weight-bolder text-dark mt-4",attrs:{"for":"column-select"}},[_vm._v(_vm._s(_vm.$t('GENERAL.COLUMNS_SELECT')))]),_c('vSelect',{staticClass:"form-control form-control--company-select",attrs:{"id":"column-select","hide-selected":"","options":_vm.columnOptions,"multiple":""},on:{"input":_vm.updateSelectedColumns},model:{value:(_vm.selectedColumns),callback:function ($$v) {_vm.selectedColumns=$$v},expression:"selectedColumns"}})],1),_c('div',{staticClass:"table-container"},[(_vm.isGenerated)?_c('table',{staticClass:"table table-head-custom table-vertical-center table-head-bg",staticStyle:{"width":"100%","overflow-x":"auto"}},[_c('thead',{staticStyle:{"width":"100%"}},[_c('tr',{staticClass:"text-uppercase",staticStyle:{"width":"100%","max-width":"150px"}},[(
              _vm.visibleColumns.some((column) => column.key === 'deviceName')
            )?_c('th',{staticClass:"pl-6"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.orderByDeviceName}},[_c('span',{staticClass:"pt-2",class:{ 'text-primary': _vm.deviceNameOrder.isActive }},[_vm._v(" "+_vm._s(_vm.$t('COMPANIES.DEVICE_NAME'))+" ")]),_c('span',{staticClass:"svg-icon svg-icon-sm pl-2",class:{
                  'svg-icon-primary': _vm.deviceNameOrder.isActive,
                }},[(_vm.deviceNameOrder.query === 'asc')?_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Down-2.svg"}}):_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Up-2.svg"}})],1)])]):_vm._e(),(_vm.visibleColumns.some((column) => column.key === 'location'))?_c('th',{staticClass:"text-uppercase",staticStyle:{"max-width":"150px"}},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.LOCATION'))+" ")]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'lessor')
            )?_c('th',{staticClass:"text-left",staticStyle:{"max-width":"150px"}},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.LESSOR'))+" ")]):_vm._e(),(
              (_vm.currentUserCompanyModule[0] === 'gaming' ||
                _vm.currentUserCompanyModule[0] === 'parking') &&
              _vm.isAdvancedView &&
              _vm.visibleColumns.some((column) => column.key === 'netCoin')
            )?_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.NETTO_COIN'))+" ")]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'coin')
            )?_c('th',{staticClass:"text-right cursor-pointer"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.orderByCoin}},[_c('span',{staticClass:"pt-2",class:{ 'text-primary': _vm.coinOrder.isActive }},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Brutto Coin'),expression:"'Brutto Coin'",modifiers:{"hover":true}}]},[_vm._v(_vm._s(_vm.$t('GENERAL.COIN')))])]),_c('span',{staticClass:"svg-icon svg-icon-sm pl-2",class:{
                  'svg-icon-primary': _vm.coinOrder.isActive,
                }},[(_vm.coinOrder.query === 'asc')?_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Down-2.svg"}}):_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Up-2.svg"}})],1)])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.isAdvancedView &&
              _vm.visibleColumns.some((column) => column.key === 'netSms')
            )?_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.NETTO_SMS'))+" ")]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'sms')
            )?_c('th',{staticClass:"text-right cursor-pointer"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.orderBySms}},[_c('span',{staticClass:"pt-2",class:{ 'text-primary': _vm.smsOrder.isActive }},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Brutto SMS'),expression:"'Brutto SMS'",modifiers:{"hover":true}}]},[_vm._v(_vm._s(_vm.$t('GENERAL.SMS')))])]),_c('span',{staticClass:"svg-icon svg-icon-sm pl-2",class:{
                  'svg-icon-primary': _vm.smsOrder.isActive,
                }},[(_vm.smsOrder.query === 'asc')?_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Down-2.svg"}}):_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Up-2.svg"}})],1)])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.isAdvancedView &&
              _vm.visibleColumns.some((column) => column.key === 'netCard')
            )?_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.NETTO_CARD'))+" ")]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'card')
            )?_c('th',{staticClass:"text-right cursor-pointer"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.orderBySms}},[_c('span',{staticClass:"pt-2",class:{ 'text-primary': _vm.smsOrder.isActive }},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Brutto Card'),expression:"'Brutto Card'",modifiers:{"hover":true}}]},[_vm._v(_vm._s(_vm.$t('GENERAL.CARD')))])]),_c('span',{staticClass:"svg-icon svg-icon-sm pl-2",class:{
                  'svg-icon-primary': _vm.smsOrder.isActive,
                }},[(_vm.smsOrder.query === 'asc')?_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Down-2.svg"}}):_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Up-2.svg"}})],1)])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.isAdvancedView &&
              _vm.visibleColumns.some((column) => column.key === 'net')
            )?_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.NETTO_CSC'))+" ")]):_vm._e(),(
              (_vm.currentUserCompanyModule[0] === 'gaming' ||
                _vm.currentUserCompanyModule[0] === 'parking') &&
              _vm.isAdvancedView &&
              _vm.visibleColumns.some((column) => column.key === 'pdv')
            )?_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.PDV_CSC'))+" ")]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'loyalty')
            )?_c('th',{staticClass:"text-right"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.orderByLoyalty}},[_c('span',{staticClass:"pt-2",class:{ 'text-primary': _vm.loyaltyOrder.isActive }},[_c('span',[_vm._v("Loyalty")])]),_c('span',{staticClass:"svg-icon svg-icon-sm pl-2",class:{
                  'svg-icon-primary': _vm.loyaltyOrder.isActive,
                }},[(_vm.loyaltyOrder.query === 'asc')?_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Down-2.svg"}}):_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Up-2.svg"}})],1)])]):_vm._e(),(_vm.visibleColumns.some((column) => column.key === 'csc'))?_c('th',{staticClass:"text-right cursor-pointer"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.orderByCs}},[_c('span',{staticClass:"pt-2",class:{ 'text-primary': _vm.cSOrder.isActive }},[(
                    _vm.visibleColumns.some((column) => column.key === 'csc')
                  )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Brutto Coin + SMS + Card'),expression:"'Brutto Coin + SMS + Card'",modifiers:{"hover":true}}]},[_vm._v(_vm._s(_vm.$t('GENERAL.FINANCE_TOTAL')))]):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Brutto Total'),expression:"'Brutto Total'",modifiers:{"hover":true}}]},[_vm._v(_vm._s(_vm.$t('GENERAL.TOTAL')))])]),_c('span',{staticClass:"svg-icon svg-icon-sm pl-2",class:{
                  'svg-icon-primary': _vm.cSOrder.isActive,
                }},[(_vm.cSOrder.query === 'asc')?_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Down-2.svg"}}):_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Up-2.svg"}})],1)])]):_vm._e(),(
              _vm.isTokenColumnVisible &&
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'token')
            )?_c('th',{staticClass:"text-right"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.orderByToken}},[_c('span',{staticClass:"pt-2",class:{ 'text-primary': _vm.tokenOrder.isActive }},[_c('span',[_vm._v("Token")])]),_c('span',{staticClass:"svg-icon svg-icon-sm pl-2",class:{
                  'svg-icon-primary': _vm.tokenOrder.isActive,
                }},[(_vm.tokenOrder.query === 'asc')?_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Down-2.svg"}}):_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Up-2.svg"}})],1)])]):_vm._e(),(
              (_vm.currentUserCompanyModule[0] === 'gaming' ||
                _vm.currentUserCompanyModule[0] === 'parking') &&
              _vm.visibleColumns.some((column) => column.key === 'total')
            )?_c('th',{staticClass:"text-right cursor-pointer pr-6"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.orderByTotal}},[_c('span',{staticClass:"pt-2",class:{ 'text-primary': _vm.totalOrder.isActive }},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Coin + SMS + Loyalty'),expression:"'Coin + SMS + Loyalty'",modifiers:{"hover":true}}]},[_vm._v(_vm._s(_vm.$t('GENERAL.TOTAL')))])]),_c('span',{staticClass:"svg-icon svg-icon-sm pl-2",class:{
                  'svg-icon-primary': _vm.totalOrder.isActive,
                }},[(_vm.totalOrder.query === 'asc')?_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Down-2.svg"}}):_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Up-2.svg"}})],1)])]):_vm._e()])]),_c('tbody',{staticStyle:{"width":"100%","height":"40vh"}},[_vm._l((_vm.data.filter(
            (d) => this.isDevicesWithoutTrafficVisible || d.total !== 0
          )),function(rDevice,key){return _c('tr',{key:key + '_' + key,staticStyle:{"width":"100%"}},[(
              _vm.visibleColumns.some((column) => column.key === 'deviceName')
            )?_c('td',{staticClass:"pl-6",class:{ 'border-top-0': key === 0 }},[_c('span',{staticClass:"text-dark-75 font-weight-bolder mb-1 font-size-lg"},[_vm._v(_vm._s(rDevice.name))]),_c('div',_vm._l((rDevice.tags),function(tag,key){return _c('span',{key:key,staticClass:"label label-sm label-light-primary label-inline mr-2"},[_vm._v(_vm._s(tag))])}),0),_c('div',{staticClass:"mt-1"},[_vm._v(_vm._s(rDevice.imei))])]):_vm._e(),(_vm.visibleColumns.some((column) => column.key === 'location'))?_c('td',{staticClass:"text-left",class:{ 'border-top-0': key === 0 }},[_vm._v(" "+_vm._s(rDevice.location)+" ")]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'lessor')
            )?_c('td',{staticClass:"text-left",class:{ 'border-top-0': key === 0 }},[_vm._v(" "+_vm._s(rDevice.lessor)+" ")]):_vm._e(),(
              (_vm.currentUserCompanyModule[0] === 'gaming' ||
                _vm.currentUserCompanyModule[0] === 'parking') &&
              _vm.isAdvancedView &&
              _vm.visibleColumns.some((column) => column.key === 'netCoin')
            )?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(rDevice.netCoin !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(rDevice.netCoin))+" ")]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'coin')
            )?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(rDevice.coin !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(rDevice.coin))+" ")]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.isAdvancedView &&
              _vm.visibleColumns.some((column) => column.key === 'netSms')
            )?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(rDevice.netSms !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(rDevice.netSms))+" ")]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'sms')
            )?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(rDevice.sms !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(rDevice.sms)))]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.isAdvancedView &&
              _vm.visibleColumns.some((column) => column.key === 'netCard')
            )?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(rDevice.netCard !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(rDevice.netCard))+" ")]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'card')
            )?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(rDevice.card !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(rDevice.card)))]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.isAdvancedView &&
              _vm.visibleColumns.some((column) => column.key === 'net')
            )?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(rDevice.net !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(rDevice.net)))]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e(),(
              (_vm.currentUserCompanyModule[0] === 'gaming' ||
                _vm.currentUserCompanyModule[0] === 'parking') &&
              _vm.isAdvancedView &&
              _vm.visibleColumns.some((column) => column.key === 'pdv')
            )?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(rDevice.pdv !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(rDevice.pdv)))]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'loyalty')
            )?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(rDevice.loyalty !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(rDevice.loyalty))+" ")]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e(),(
              _vm.visibleColumns.some((column) => column.key === 'csc')
            )?_c('td',{staticClass:"text-dark-75 font-weight-bolder font-italic text-right pr-6",class:{ 'border-top-0': key === 0 }},[(rDevice.csc !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(rDevice.csc)))]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e(),(
              _vm.isTokenColumnVisible &&
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'token')
            )?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(rDevice.token !== 0)?_c('span',[_vm._v(_vm._s(rDevice.token)+" ")]):_c('span',[_vm._v("0")])]):_vm._e(),(
              (_vm.currentUserCompanyModule[0] === 'gaming' ||
                _vm.currentUserCompanyModule[0] === 'parking') &&
              _vm.visibleColumns.some((column) => column.key === 'total')
            )?_c('td',{staticClass:"text-dark-75 font-weight-bolder font-italic text-right pr-6",class:{ 'border-top-0': key === 0 }},[(rDevice.total !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm._f("moneyValueFormat")(rDevice.total))+" ")]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0")])]):_vm._e()])}),_c('tr',[_c('td',{staticClass:"pt-10 pl-6 font-size-lg font-weight-bolder text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.TOTAL'))+": ")]),_c('td'),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('td'):_vm._e(),(
              (_vm.currentUserCompanyModule[0] === 'gaming' ||
                _vm.currentUserCompanyModule[0] === 'parking') &&
              _vm.visibleColumns.some((column) => column.key === 'netCoin')
            )?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase"},[(_vm.generateReportNetCoin !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.generateReportNetCoin))+" ")]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'coin')
            )?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase"},[(_vm.generateReportCoin !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.generateReportCoin))+" ")]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'netSms')
            )?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase"},[(_vm.generateReportNetSms !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.generateReportNetSms)))]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'sms')
            )?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase"},[(_vm.generateReportSms !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.generateReportSms))+" ")]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'netCard')
            )?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase"},[(_vm.generateReportNetCard !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.generateReportNetCard)))]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'card')
            )?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase"},[(_vm.generateReportCard !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.generateReportCard))+" ")]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'net')
            )?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(_vm.generateReportNet))+" ")]):_vm._e(),(
              (_vm.currentUserCompanyModule[0] === 'gaming' ||
                _vm.currentUserCompanyModule[0] === 'parking') &&
              _vm.visibleColumns.some((column) => column.key === 'pdv')
            )?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(_vm.generateReportPdv))+" ")]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'loyalty')
            )?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase pr-6"},[(_vm.generateReportLoyalty !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.generateReportLoyalty)))]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e(),(
             (_vm.currentUserCompanyModule[0] === 'gaming' || _vm.currentUserCompanyModule[0] === 'parking') &&
              _vm.visibleColumns.some((column) => column.key === 'csc')
            )?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(_vm.generateReportCsc))+" ")]):_vm._e(),(
              _vm.isTokenColumnVisible &&
              _vm.currentUserCompanyModule[0] === 'gaming' &&
              _vm.visibleColumns.some((column) => column.key === 'token')
            )?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase"},[(_vm.generateReportToken !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.generateReportToken))]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0")])]):_vm._e(),(
              (_vm.currentUserCompanyModule[0] === 'gaming' ||
                _vm.currentUserCompanyModule[0] === 'parking') &&
              _vm.visibleColumns.some((column) => column.key === 'total')
            )?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase pr-6"},[(_vm.generateReportTotal !== 0)?_c('span',{staticStyle:{"white-space":"nowrap"}}):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("0 €")])]):_vm._e()])],2)]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }