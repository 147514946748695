<template>
  <div>
    <div class="d-flex">
      <div class="card-toolbar mb-2 mr-2">
        <button
          @click="toggleAdvancedView"
          class="btn btn-light-primary font-weight-bold px-10"
        >
          {{
            isAdvancedView
              ? $t('GENERAL.SIMPLE_VIEW')
              : $t('GENERAL.ADVANCED_VIEW')
          }}
        </button>
      </div>
      <!-- <div class="card-toolbar mb-2">
        <button
          @click="toggleDevicesWithoutTraffic"
          class="btn btn-light-primary font-weight-bold px-10"
        >
          {{ isDevicesWithoutTrafficVisible ? 'Hide' : 'Show' }} devices without
          traffic
        </button>
      </div> -->
    </div>
    <div class ="mb-4 pb-0">
      <div class="form-group">
        <label for="column-select" class="font-weight-bolder text-dark mt-4">{{
          $t('GENERAL.COLUMNS_SELECT')
        }}</label>

        <vSelect
          id="column-select"
          hide-selected
          v-model="selectedColumns"
          :options="columnOptions"
          multiple
          @input="updateSelectedColumns"
          class="form-control form-control--company-select"
        />
      </div>
      <div class="table-container">
        <table
          v-if="isGenerated"
          class="table table-head-custom table-vertical-center table-head-bg"
          style="width: 100%; overflow-x: auto"
        >
        <thead style="width: 100%">
          <tr class="text-uppercase" style="width: 100%; max-width: 150px">
            <th
              class="pl-6"
              v-if="
                visibleColumns.some((column) => column.key === 'deviceName')
              "
            >
              <span @click="orderByDeviceName" class="cursor-pointer">
                <span
                  class="pt-2"
                  :class="{ 'text-primary': deviceNameOrder.isActive }"
                >
                  {{ $t('COMPANIES.DEVICE_NAME') }}
                </span>
                <span
                  class="svg-icon svg-icon-sm pl-2"
                  :class="{
                    'svg-icon-primary': deviceNameOrder.isActive,
                  }"
                >
                  <inline-svg
                    v-if="deviceNameOrder.query === 'asc'"
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                  <inline-svg
                    v-else
                    src="media/svg/icons/Navigation/Up-2.svg"
                  />
                </span>
              </span>
            </th>
            <th
              class="text-uppercase"
              style="max-width: 150px"
              v-if="visibleColumns.some((column) => column.key === 'location')"
            >
              {{ $t('GENERAL.LOCATION') }}
            </th>
            <th
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'lessor')
              "
              class="text-left"
              style="max-width: 150px"
            >
              {{ $t('GENERAL.LESSOR') }}
            </th>
            <th
              v-if="
                (currentUserCompanyModule[0] === 'gaming' ||
                  currentUserCompanyModule[0] === 'parking') &&
                isAdvancedView &&
                visibleColumns.some((column) => column.key === 'netCoin')
              "
              class="text-right"
            >
              {{ $t('GENERAL.NETTO_COIN') }}
            </th>
            <th
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'coin')
              "
              class="text-right cursor-pointer"
            >
              <span @click="orderByCoin" class="cursor-pointer">
                <span
                  class="pt-2"
                  :class="{ 'text-primary': coinOrder.isActive }"
                >
                  <span v-b-tooltip.hover="'Brutto Coin'">{{
                    $t('GENERAL.COIN')
                  }}</span></span
                >
                <span
                  class="svg-icon svg-icon-sm pl-2"
                  :class="{
                    'svg-icon-primary': coinOrder.isActive,
                  }"
                >
                  <inline-svg
                    v-if="coinOrder.query === 'asc'"
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                  <inline-svg
                    v-else
                    src="media/svg/icons/Navigation/Up-2.svg"
                  />
                </span>
              </span>
            </th>
            <th
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                isAdvancedView &&
                visibleColumns.some((column) => column.key === 'netSms')
              "
              class="text-right"
            >
              {{ $t('GENERAL.NETTO_SMS') }}
            </th>
            <th
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'sms')
              "
              class="text-right cursor-pointer"
            >
              <span @click="orderBySms" class="cursor-pointer">
                <span
                  class="pt-2"
                  :class="{ 'text-primary': smsOrder.isActive }"
                >
                  <span v-b-tooltip.hover="'Brutto SMS'">{{
                    $t('GENERAL.SMS')
                  }}</span></span
                >
                <span
                  class="svg-icon svg-icon-sm pl-2"
                  :class="{
                    'svg-icon-primary': smsOrder.isActive,
                  }"
                >
                  <inline-svg
                    v-if="smsOrder.query === 'asc'"
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                  <inline-svg
                    v-else
                    src="media/svg/icons/Navigation/Up-2.svg"
                  />
                </span>
              </span>
            </th>
            <th
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                isAdvancedView &&
                visibleColumns.some((column) => column.key === 'netCard')
              "
              class="text-right"
            >
              {{ $t('GENERAL.NETTO_CARD') }}
            </th>
            <th
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'card')
              "
              class="text-right cursor-pointer"
            >
              <span @click="orderBySms" class="cursor-pointer">
                <span
                  class="pt-2"
                  :class="{ 'text-primary': smsOrder.isActive }"
                >
                  <span v-b-tooltip.hover="'Brutto Card'">{{
                    $t('GENERAL.CARD')
                  }}</span></span
                >
                <span
                  class="svg-icon svg-icon-sm pl-2"
                  :class="{
                    'svg-icon-primary': smsOrder.isActive,
                  }"
                >
                  <inline-svg
                    v-if="smsOrder.query === 'asc'"
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                  <inline-svg
                    v-else
                    src="media/svg/icons/Navigation/Up-2.svg"
                  />
                </span>
              </span>
            </th>
            <th
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                isAdvancedView &&
                visibleColumns.some((column) => column.key === 'net')
              "
              class="text-right"
            >
              {{ $t('GENERAL.NETTO_CSC') }}
            </th>
            <th
              v-if="
                (currentUserCompanyModule[0] === 'gaming' ||
                  currentUserCompanyModule[0] === 'parking') &&
                isAdvancedView &&
                visibleColumns.some((column) => column.key === 'pdv')
              "
              class="text-right"
            >
              {{ $t('GENERAL.PDV_CSC') }}
            </th>
            <th
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'loyalty')
              "
              class="text-right"
            >
              <span @click="orderByLoyalty" class="cursor-pointer">
                <span
                  class="pt-2"
                  :class="{ 'text-primary': loyaltyOrder.isActive }"
                >
                  <span>Loyalty</span></span
                >
                <span
                  class="svg-icon svg-icon-sm pl-2"
                  :class="{
                    'svg-icon-primary': loyaltyOrder.isActive,
                  }"
                >
                  <inline-svg
                    v-if="loyaltyOrder.query === 'asc'"
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                  <inline-svg
                    v-else
                    src="media/svg/icons/Navigation/Up-2.svg"
                  />
                </span>
              </span>
            </th>
            <th
              v-if="visibleColumns.some((column) => column.key === 'csc')"
              class="text-right cursor-pointer"
            >
              <span @click="orderByCs" class="cursor-pointer">
                <span
                  class="pt-2"
                  :class="{ 'text-primary': cSOrder.isActive }"
                >
                  <span
                    v-if="
                      visibleColumns.some((column) => column.key === 'csc')
                    "
                    v-b-tooltip.hover="'Brutto Coin + SMS + Card'"
                    >{{ $t('GENERAL.FINANCE_TOTAL') }}</span
                  >
                  <span v-else v-b-tooltip.hover="'Brutto Total'">{{
                    $t('GENERAL.TOTAL')
                  }}</span>
                </span>
                <span
                  class="svg-icon svg-icon-sm pl-2"
                  :class="{
                    'svg-icon-primary': cSOrder.isActive,
                  }"
                >
                  <inline-svg
                    v-if="cSOrder.query === 'asc'"
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                  <inline-svg
                    v-else
                    src="media/svg/icons/Navigation/Up-2.svg"
                  />
                </span>
              </span>
            </th>
            <th
              v-if="
                isTokenColumnVisible &&
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'token')
              "
              class="text-right"
            >
              <span @click="orderByToken" class="cursor-pointer">
                <span
                  class="pt-2"
                  :class="{ 'text-primary': tokenOrder.isActive }"
                >
                  <span>Token</span></span
                >
                <span
                  class="svg-icon svg-icon-sm pl-2"
                  :class="{
                    'svg-icon-primary': tokenOrder.isActive,
                  }"
                >
                  <inline-svg
                    v-if="tokenOrder.query === 'asc'"
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                  <inline-svg
                    v-else
                    src="media/svg/icons/Navigation/Up-2.svg"
                  />
                </span>
              </span>
            </th>
            <th
              v-if="
                (currentUserCompanyModule[0] === 'gaming' ||
                  currentUserCompanyModule[0] === 'parking') &&
                visibleColumns.some((column) => column.key === 'total')
              "
              class="text-right cursor-pointer pr-6"
            >
              <span @click="orderByTotal" class="cursor-pointer">
                <span
                  class="pt-2"
                  :class="{ 'text-primary': totalOrder.isActive }"
                >
                  <span v-b-tooltip.hover="'Coin + SMS + Loyalty'">{{
                    $t('GENERAL.TOTAL')
                  }}</span></span
                >
                <span
                  class="svg-icon svg-icon-sm pl-2"
                  :class="{
                    'svg-icon-primary': totalOrder.isActive,
                  }"
                >
                  <inline-svg
                    v-if="totalOrder.query === 'asc'"
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                  <inline-svg
                    v-else
                    src="media/svg/icons/Navigation/Up-2.svg"
                  />
                </span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody style="width: 100%; height: 40vh">
          <tr
            v-for="(rDevice, key) in data.filter(
              (d) => this.isDevicesWithoutTrafficVisible || d.total !== 0
            )"
            :key="key + '_' + key"
            style="width: 100%"
          >
            <td
              class="pl-6"
              :class="{ 'border-top-0': key === 0 }"
              v-if="
                visibleColumns.some((column) => column.key === 'deviceName')
              "
            >
              <span class="text-dark-75 font-weight-bolder mb-1 font-size-lg">{{
                rDevice.name
              }}</span>
              <div>
                <span
                  v-for="(tag, key) in rDevice.tags"
                  :key="key"
                  class="label label-sm label-light-primary label-inline mr-2"
                  >{{ tag }}</span
                >
              </div>
              <div class="mt-1">{{ rDevice.imei }}</div>
            </td>
            <td
              class="text-left"
              :class="{ 'border-top-0': key === 0 }"
              v-if="visibleColumns.some((column) => column.key === 'location')"
            >
              {{ rDevice.location }}
            </td>
            <td
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'lessor')
              "
              class="text-left"
              :class="{ 'border-top-0': key === 0 }"
            >
              {{ rDevice.lessor }}
            </td>
            <td
              v-if="
                (currentUserCompanyModule[0] === 'gaming' ||
                  currentUserCompanyModule[0] === 'parking') &&
                isAdvancedView &&
                visibleColumns.some((column) => column.key === 'netCoin')
              "
              :class="{ 'border-top-0': key === 0 }"
              class="text-right"
            >
              <span style="white-space: nowrap" v-if="rDevice.netCoin !== 0"
                >{{ rDevice.netCoin | currencyFormat }}
              </span>
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>
            <td
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'coin')
              "
              :class="{ 'border-top-0': key === 0 }"
              class="text-right"
            >
              <span style="white-space: nowrap" v-if="rDevice.coin !== 0"
                >{{ rDevice.coin | currencyFormat }}
              </span>
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>
            <td
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                isAdvancedView &&
                visibleColumns.some((column) => column.key === 'netSms')
              "
              :class="{ 'border-top-0': key === 0 }"
              class="text-right"
            >
              <span style="white-space: nowrap" v-if="rDevice.netSms !== 0"
                >{{ rDevice.netSms | currencyFormat }}
              </span>
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>
            <td
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'sms')
              "
              :class="{ 'border-top-0': key === 0 }"
              class="text-right"
            >
              <span style="white-space: nowrap" v-if="rDevice.sms !== 0">{{
                rDevice.sms | currencyFormat
              }}</span>
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>
            <td
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                isAdvancedView &&
                visibleColumns.some((column) => column.key === 'netCard')
              "
              :class="{ 'border-top-0': key === 0 }"
              class="text-right"
            >
              <span style="white-space: nowrap" v-if="rDevice.netCard !== 0"
                >{{ rDevice.netCard | currencyFormat }}
              </span>
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>
            <td
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'card')
              "
              :class="{ 'border-top-0': key === 0 }"
              class="text-right"
            >
              <span style="white-space: nowrap" v-if="rDevice.card !== 0">{{
                rDevice.card | currencyFormat
              }}</span>
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>
            <td
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                isAdvancedView &&
                visibleColumns.some((column) => column.key === 'net')
              "
              class="text-right"
              :class="{ 'border-top-0': key === 0 }"
            >
              <span style="white-space: nowrap" v-if="rDevice.net !== 0">{{
                rDevice.net | currencyFormat
              }}</span>
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>
            <td
              v-if="
                (currentUserCompanyModule[0] === 'gaming' ||
                  currentUserCompanyModule[0] === 'parking') &&
                isAdvancedView &&
                visibleColumns.some((column) => column.key === 'pdv')
              "
              class="text-right"
              :class="{ 'border-top-0': key === 0 }"
            >
              <span style="white-space: nowrap" v-if="rDevice.pdv !== 0">{{
                rDevice.pdv | currencyFormat
              }}</span>
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>
            <td
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'loyalty')
              "
              :class="{ 'border-top-0': key === 0 }"
              class="text-right"
            >
              <span style="white-space: nowrap" v-if="rDevice.loyalty !== 0"
                >{{ rDevice.loyalty | currencyFormat }}
              </span>
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>
            <td
              v-if="
                visibleColumns.some((column) => column.key === 'csc')
              "
              :class="{ 'border-top-0': key === 0 }"
              class="text-dark-75 font-weight-bolder font-italic text-right pr-6"
            >
              <span style="white-space: nowrap" v-if="rDevice.csc !== 0">{{
                rDevice.csc | currencyFormat
              }}</span>
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>
            <td
              v-if="
                isTokenColumnVisible &&
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'token')
              "
              :class="{ 'border-top-0': key === 0 }"
              class="text-right"
            >
              <span v-if="rDevice.token !== 0">{{ rDevice.token }} </span>
              <span v-else>0</span>
            </td>
            <td
              v-if="
                (currentUserCompanyModule[0] === 'gaming' ||
                  currentUserCompanyModule[0] === 'parking') &&
                visibleColumns.some((column) => column.key === 'total')
              "
              :class="{ 'border-top-0': key === 0 }"
              class="text-dark-75 font-weight-bolder font-italic text-right pr-6"
            >
              <span style="white-space: nowrap" v-if="rDevice.total !== 0">
                {{ rDevice.total | moneyValueFormat }}
              </span>
              <span style="white-space: nowrap" v-else>0</span>
            </td>
          </tr>
          <tr>
            <td
              class="pt-10 pl-6 font-size-lg font-weight-bolder text-uppercase"
            >
              {{ $t('GENERAL.TOTAL') }}:
            </td>
            <td></td>
            <td v-if="currentUserCompanyModule[0] === 'gaming'"></td>
            <td
              v-if="
                (currentUserCompanyModule[0] === 'gaming' ||
                  currentUserCompanyModule[0] === 'parking') &&
                visibleColumns.some((column) => column.key === 'netCoin')
              "
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              <span
                style="white-space: nowrap"
                v-if="generateReportNetCoin !== 0"
                >{{ generateReportNetCoin | currencyFormat }}
              </span>
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>
            <td
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'coin')
              "
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              <span style="white-space: nowrap" v-if="generateReportCoin !== 0"
                >{{ generateReportCoin | currencyFormat }}
              </span>
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>
            <td
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'netSms')
              "
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              <span
                style="white-space: nowrap"
                v-if="generateReportNetSms !== 0"
                >{{ generateReportNetSms | currencyFormat }}</span
              >
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>
            <td
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'sms')
              "
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              <span style="white-space: nowrap" v-if="generateReportSms !== 0"
                >{{ generateReportSms | currencyFormat }}
              </span>
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>
            <td
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'netCard')
              "
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              <span
                style="white-space: nowrap"
                v-if="generateReportNetCard !== 0"
                >{{ generateReportNetCard | currencyFormat }}</span
              >
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>
            <td
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'card')
              "
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              <span style="white-space: nowrap" v-if="generateReportCard !== 0"
                >{{ generateReportCard | currencyFormat }}
              </span>
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>

            <td
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'net')
              "
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
              style="white-space: nowrap"
            >
              {{ generateReportNet | currencyFormat }}
            </td>
            <td
              v-if="
                (currentUserCompanyModule[0] === 'gaming' ||
                  currentUserCompanyModule[0] === 'parking') &&
                visibleColumns.some((column) => column.key === 'pdv')
              "
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
              style="white-space: nowrap"
            >
              {{ generateReportPdv | currencyFormat }}
            </td>
            <td
              v-if="
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'loyalty')
              "
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase pr-6"
            >
              <span
                style="white-space: nowrap"
                v-if="generateReportLoyalty !== 0"
                >{{ generateReportLoyalty | currencyFormat }}</span
              >
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>
            <td
              v-if="
               (currentUserCompanyModule[0] === 'gaming' || currentUserCompanyModule[0] === 'parking') &&
                visibleColumns.some((column) => column.key === 'csc')
              "
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
              style="white-space: nowrap"
            >
              {{ generateReportCsc | currencyFormat }}
            </td>

            <td
              v-if="
                isTokenColumnVisible &&
                currentUserCompanyModule[0] === 'gaming' &&
                visibleColumns.some((column) => column.key === 'token')
              "
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              <span
                style="white-space: nowrap"
                v-if="generateReportToken !== 0"
                >{{ generateReportToken }}</span
              >
              <span style="white-space: nowrap" v-else>0</span>
            </td>
            <td
              v-if="
                (currentUserCompanyModule[0] === 'gaming' ||
                  currentUserCompanyModule[0] === 'parking') &&
                visibleColumns.some((column) => column.key === 'total')
              "
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase pr-6"
            >
              <span
                style="white-space: nowrap"
                v-if="generateReportTotal !== 0"
              >
              <!-- {{ generateReportTotal | currencyFormat }} -->
              </span>
              <span style="white-space: nowrap" v-else>0 €</span>
            </td>
          </tr>
        </tbody>
      </table></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  name: 'DeviceReportTable',
  props: {
    traffic: { type: Array },
    selectedDevices: { type: Array },
    isTokenColumnVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib', 'currentUserCompanyModule']),
    visibleColumns() {
      const isCompanyInParkingMode =
        this.currentUserCompanyModule[0] === 'parking'
      return isCompanyInParkingMode
        ? this.isAdvancedView
          ? [
              ...this.columns.filter(
                (column) => column.visible && column.isParkingMode
              ),
              ...this.advancedViewColumns.filter(
                (column) => column.visible && column.isParkingMode
              ),
            ]
          : this.columns.filter(
              (column) => column.visible && column.isParkingMode
            )
        : this.isAdvancedView
        ? [
            ...this.columns.filter((column) => column.visible),
            ...this.advancedViewColumns.filter((column) => column.visible),
          ]
        : this.columns.filter((column) => column.visible)
    },
    columnOptions() {
      const isCompanyInParkingMode =
        this.currentUserCompanyModule[0] === 'parking'
      return isCompanyInParkingMode
        ? [
            ...this.filteredColumns.filter((column) => column.isParkingMode),
            ...this.advancedViewColumns.filter(
              (column) => column.isParkingMode
            ),
          ].map((column) => column.name)
        : (this.isAdvancedView
            ? [...this.filteredColumns, ...this.advancedViewColumns]
            : this.filteredColumns
          ).map((column) => column.name)
    },
  },
  components: {
    vSelect,
  },
  data() {
    return {
      data: [],
      selectedColumns: [],
      columns: [
        { key: 'deviceName', name: '', visible: true, isParkingMode: true },
        { key: 'location', name: '', visible: true, isParkingMode: true },
        { key: 'lessor', name: '', visible: true, isParkingMode: false },
        { key: 'coin', name: '', visible: true, isParkingMode: false },
        { key: 'sms', name: 'SMS', visible: true, isParkingMode: false },
        { key: 'card', name: 'Card', visible: true, isParkingMode: false },
        { key: 'csc', name: 'CSC', visible: true, isParkingMode: true },
        {
          key: 'loyalty',
          name: 'Loyalty',
          visible: true,
          isParkingMode: false,
        },
        { key: 'token', name: 'Token', visible: false, isParkingMode: false },
        { key: 'total', name: '', visible: false, isParkingMode: true },
      ],
      advancedViewColumns: [
        {
          key: 'netCoin',
          name: 'Netto Coin',
          visible: true,
          isParkingMode: true,
        },
        {
          key: 'netSms',
          name: 'Netto SMS',
          visible: true,
          isParkingMode: false,
        },
        {
          key: 'netCard',
          name: 'Netto Card',
          visible: true,
          isParkingMode: false,
        },
        { key: 'net', name: 'Netto CSC', visible: true, isParkingMode: false },
        { key: 'pdv', name: 'Pdv CSC', visible: true, isParkingMode: true },
      ],
      filteredColumns: [],
      generateReportNetCoin: 0,
      generateReportCoin: 0,
      generateReportNetSms: 0,
      generateReportSms: 0,
      generateReportNetCard: 0,
      generateReportCard: 0,
      generateReportNetCsc: 0,
      generateReportCsc: 0,
      generateReportNet: 0,
      generateReportPdv: 0,
      generateReportAmount: 0,
      generateReportLoyalty: 0,
      generateReportToken: 0,
      generateReportTotal: 0,
      token: 0,
      deviceNameOrder: { query: 'asc', isActive: false },
      coinOrder: { query: 'asc', isActive: false },
      smsOrder: { query: 'asc', isActive: false },
      cardOrder: { query: 'asc', isActive: false },
      cSOrder: { query: 'asc', isActive: false },
      loyaltyOrder: { query: 'asc', isActive: false },
      tokenOrder: { query: 'asc', isActive: false },
      totalOrder: { query: 'asc', isActive: false },
      isGenerated: false,
      isDevicesWithoutTrafficVisible: true,
      devicesWithoutTraffic: [],
      isAdvancedView: false,
    }
  },
  mounted() {
    this.filteredColumns = this.columns.filter(
      (column) =>
        column.key !== 'deviceName' &&
        column.key !== 'location' &&
        column.key !== 'lessor'
    )
    this.devicesWithoutTraffic = this.selectedDevices.filter(
      (di) =>
        !this.traffic.map((transaction) => transaction.imei).includes(di.imei)
    )
    this.traffic.forEach((transaction) => {
      let netCoin = 0
      if (
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci'
      ) {
        netCoin = parseFloat(transaction.amount * 0.8)
        this.generateReportNetCoin += netCoin
      }

      let coin = 0
      if (
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci'
      ) {
        coin = parseFloat(transaction.amount)
        this.generateReportCoin += coin
      }

      let netSms = 0
      if (transaction.paymentType === 'pay_sms') {
        netSms = parseFloat(transaction.amount * 0.8)
        this.generateReportNetSms += netSms
      }

      let sms = 0
      if (transaction.paymentType === 'pay_sms') {
        sms = parseFloat(transaction.amount)
        this.generateReportSms += sms
      }

      let netCard = 0
      if (transaction.paymentType === 'pay_card') {
        netCard = parseFloat(transaction.amount * 0.8)
        this.generateReportNetCard += netCard
      }

      let card = 0
      if (transaction.paymentType === 'pay_card') {
        card = parseFloat(transaction.amount)
        this.generateReportCard += card
      }

      let netCsc = 0
      if (
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci' ||
        transaction.paymentType === 'pay_sms' ||
        transaction.paymentType === 'pay_card'
      ) {
        csc = parseFloat(transaction.amount * 0.8)
        this.generateReportNetCsc += netCsc
      }

      let csc = 0
      if (
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci' ||
        transaction.paymentType === 'pay_sms' ||
        transaction.paymentType === 'pay_card'
      ) {
        csc = parseFloat(transaction.amount)
        this.generateReportCsc += csc
      }

      let net = 0
      if (
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci' ||
        transaction.paymentType === 'pay_sms' ||
        transaction.paymentType === 'pay_card'
      ) {
        net = parseFloat(transaction.amount) * 0.8
        this.generateReportNet += net
      }

      let pdv = net * 0.25
      this.generateReportPdv += pdv

      let amount = 0
      if (
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci' ||
        transaction.paymentType === 'pay_sms' ||
        transaction.paymentType === 'pay_card'
      ) {
        amount = parseFloat(transaction.amount)
        this.generateReportAmount += amount
      }

      let loyalty = 0
      if (transaction.paymentType === 'pay_rfcard') {
        loyalty = parseFloat(transaction.amount)
        this.generateReportLoyalty += loyalty
      }

      let token = 0
      if (transaction.paymentType === 'pay_token') {
        token = parseFloat(transaction.amount)
        this.generateReportToken += token
      }

      let total = parseFloat(transaction.amount)
      this.generateReportTotal += total

      const row = this.data.findIndex(
        (device) => device.imei === transaction.imei
      )
      const col = this.data[row]

      if (col === undefined) {
        this.data.push({
          name: transaction.deviceName,
          imei: transaction.imei,
          location: transaction.location,
          address: transaction.address,
          lessor: transaction.lessor,
          tags: transaction.tags,
          paymentType: transaction.paymentType,
          netCoin: netCoin,
          coin: coin,
          netSms: netSms,
          sms: sms,
          netCard: netCard,
          card: card,
          netCsc,
          csc,
          net: net,
          pdv: pdv,
          amount: amount,
          token: token,
          loyalty: loyalty,
          total: total,
        })
      } else {
        this.data[row].netCoin += netCoin
        this.data[row].coin += coin
        this.data[row].netSms += netSms
        this.data[row].sms += sms
        this.data[row].netCard += netCard
        this.data[row].card += card
        this.data[row].netCsc += netCsc
        this.data[row].csc += csc
        this.data[row].net += net
        this.data[row].pdv += pdv
        this.data[row].amount += amount
        this.data[row].token += token
        this.data[row].loyalty += loyalty
        this.data[row].total += total
      }
    })
    // this.devicesWithoutTraffic.forEach((deviceWithoutTraffic) => {
    //   this.data.push({
    //     name: deviceWithoutTraffic.location
    //       ? deviceWithoutTraffic.location.title
    //       : deviceWithoutTraffic.title,
    //     imei: deviceWithoutTraffic.logger
    //       ? deviceWithoutTraffic.logger.imei
    //       : deviceWithoutTraffic.imei,
    //     location: deviceWithoutTraffic.location
    //       ? deviceWithoutTraffic.location.title
    //       : deviceWithoutTraffic.title,
    //     address: deviceWithoutTraffic.address,
    //     lessor: deviceWithoutTraffic.lessor
    //       ? deviceWithoutTraffic.lessor.name
    //       : deviceWithoutTraffic.location?.lessor?.name,
    //     paymentType: deviceWithoutTraffic.paymentType,
    //     netCoin: 0,
    //     coin: 0,
    //     netSms: 0,
    //     sms: 0,
    //     netCard: 0,
    //     card: 0,
    //     netCsc: 0,
    //     csc: 0,
    //     net: 0,
    //     pdv: 0,
    //     amount: 0,
    //     loyalty: 0,
    //     token: 0,
    //     total: 0,
    //   })
    // })
    this.sendData();

    setTimeout(() => {
      this.isGenerated = true
      this.updateColumnValues()
    }, 1000)
  },
  methods: {
    updateSelectedColumns(value) {
      const selectedColumns = Array.from(value)
      this.columns.forEach((column, index) => {
        this.$set(this.columns, index, {
          ...column,
          visible:
            column.key === 'deviceName' ||
            column.key === 'location' ||
            column.key === 'lessor' ||
            selectedColumns.includes(column.name),
        })
      })

      this.advancedViewColumns.forEach((column, index) => {
        this.$set(this.advancedViewColumns, index, {
          ...column,
          visible: selectedColumns.includes(column.name),
        })
      })
    },
    toggleVisibility(index) {
      this.$set(this.columns, index, {
        ...this.columns[index],
        visible: !this.columns[index].visible,
      })
    },
    updateColumnNames() {
      const translations = {
        deviceName: 'COMPANIES.DEVICE_NAME',
        location: 'GENERAL.LOCATION',
        lessor: 'GENERAL.LESSOR',
        coin: 'GENERAL.COIN',
        total: 'GENERAL.TOTAL',
      }

      for (const key in translations) {
        const column = this.columns.find((column) => column.key === key)
        if (column) {
          column.name = this.$t(translations[key])
        }
      }
    },
    updateColumnValues() {
      const values = {
        netCoin: this.generateReportNetCoin,
        coin: this.generateReportCoin,
        total: this.generateReportTotal,
        netSms: this.generateReportNetSms,
        sms: this.generateReportSms,
        netCard: this.generateReportNetCard,
        card: this.generateReportCard,
        net: this.generateReportNet,
        pdv: this.generateReportPdv,
        amount: this.generateReportAmount,
        loyalty: this.generateReportLoyalty,
        csc: this.generateReportCsc,
      }

      for (const key in values) {
        const column = this.columns.find((column) => column.key === key)
        if (column) {
          column.value = values[key]
        }
      }
    },
    toggleDevicesWithoutTraffic() {
      if (this.isDevicesWithoutTrafficVisible) {
        this.isDevicesWithoutTrafficVisible = false
      } else {
        this.isDevicesWithoutTrafficVisible = true
      }
    },
    toggleAdvancedView() {
      this.isAdvancedView = this.isAdvancedView ? false : true
    },
    orderByDeviceName() {
      this.clearActiveFilters()
      this.deviceNameOrder.isActive = true

      if (this.deviceNameOrder.query === 'asc') {
        this.deviceNameOrder.query = 'desc'
        this.data.sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
      } else {
        this.deviceNameOrder.query = 'asc'
        this.data.sort((a, b) => {
          if (a.name > b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
      }
    },
    orderByCoin() {
      this.clearActiveFilters()
      this.coinOrder.isActive = true

      if (this.coinOrder.query === 'asc') {
        this.coinOrder.query = 'desc'
        this.data.sort((a, b) => a.coin - b.coin)
      } else {
        this.coinOrder.query = 'asc'
        this.data.sort((a, b) => b.coin - a.coin)
      }
    },
    orderBySms() {
      this.clearActiveFilters()
      this.smsOrder.isActive = true

      if (this.smsOrder.query === 'asc') {
        this.smsOrder.query = 'desc'
        this.data.sort((a, b) => a.sms - b.sms)
      } else {
        this.smsOrder.query = 'asc'
        this.data.sort((a, b) => b.sms - a.sms)
      }
    },
    orderByCard() {
      this.clearActiveFilters()
      this.cardOrder.isActive = true

      if (this.cardOrder.query === 'asc') {
        this.cardOrder.query = 'desc'
        this.data.sort((a, b) => a.card - b.card)
      } else {
        this.cardOrder.query = 'asc'
        this.data.sort((a, b) => b.card - a.card)
      }
    },
    orderByCs() {
      this.clearActiveFilters()
      this.cSOrder.isActive = true

      if (this.cSOrder.query === 'asc') {
        this.cSOrder.query = 'desc'
        this.data.sort((a, b) => a.amount - b.amount)
      } else {
        this.cSOrder.query = 'asc'
        this.data.sort((a, b) => b.amount - a.amount)
      }
    },
    orderByLoyalty() {
      this.clearActiveFilters()
      this.loyaltyOrder.isActive = true

      if (this.loyaltyOrder.query === 'asc') {
        this.loyaltyOrder.query = 'desc'
        this.data.sort((a, b) => a.loyalty - b.loyalty)
      } else {
        this.loyaltyOrder.query = 'asc'
        this.data.sort((a, b) => b.loyalty - a.loyalty)
      }
    },
    orderByToken() {
      this.clearActiveFilters()
      this.tokenOrder.isActive = true

      if (this.tokenOrder.query === 'asc') {
        this.tokenOrder.query = 'desc'
        this.data.sort((a, b) => a.token - b.token)
      } else {
        this.tokenOrder.query = 'asc'
        this.data.sort((a, b) => b.token - a.token)
      }
    },
    orderByTotal() {
      this.clearActiveFilters()
      this.totalOrder.isActive = true

      if (this.totalOrder.query === 'asc') {
        this.totalOrder.query = 'desc'
        this.data.sort((a, b) => a.total - b.total)
      } else {
        this.totalOrder.query = 'asc'
        this.data.sort((a, b) => b.total - a.total)
      }
    },
    clearActiveFilters() {
      this.deviceNameOrder.isActive = false
      this.coinOrder.isActive = false
      this.smsOrder.isActive = false
      this.cSOrder.isActive = false
      this.loyaltyOrder.isActive = false
      this.totalOrder.isActive = false
    },
    sendData(){
    {
      const exportData = this.data.map(report => {
        let exportReport = {
          ['NAME']: report.name,
          ['IMEI']: report.imei,
          ['LOCATION']: report.location,
        }

        if(this.currentUserCompanyModule[0] === 'parking') {
          exportReport = {
            ...exportReport,
          ['NETTO COIN']: this.currencyFormat(report.netCoin),
          ['PDV CSC']: this.currencyFormat(report.pdv),
          ['TOTAL']: this.currencyFormat(report.total)
          }
        } else if(this.currentUserCompanyModule[0] === 'gaming') {
          exportReport = {
          ...exportReport,
          ['LESSOR']: report.lessor,
          ['NETTO COIN']: this.currencyFormat(report.netCoin),
          ['COIN']: this.currencyFormat(report.coin),
          ['NETTO SMS']: this.currencyFormat(report.netSms),
          ['SMS']: this.currencyFormat(report.sms),
          ['NETTO CARD']: this.currencyFormat(report.netCard),
          ['CARD']: this.currencyFormat(report.card),
          ['NETTO CSC']: this.currencyFormat(report.net),
          ['PDV CSC']: this.currencyFormat(report.pdv),
          ['LOYALTY']: this.currencyFormat(report.loyalty),
          ['FINANCE TOTAL']: this.currencyFormat(report.csc),
          ['TOKEN']: report.token,
          ['TOTAL']: this.currencyFormat(report.total)
          }
        }
        return exportReport
    })

    if(this.currentUserCompanyModule[0] === 'parking') {
      exportData.push({
        ['NAME']: 'TOTAL',
        ['IMEI']: "",
        ['LOCATION']: "",
        ['NETTO COIN']: this.currencyFormat(this.generateReportNetCoin),
        ['PDV CSC']: this.currencyFormat(this.generateReportPdv),
        ['TOTAL']:  ""
      })
    } else if (this.currentUserCompanyModule[0] === 'gaming') {
      exportData.push({
        ['NAME']: 'TOTAL',
        ['IMEI']: "",
        ['LOCATION']: "",
        ['LESSOR']:"",
        ['NETTO COIN']: this.currencyFormat(this.generateReportNetCoin),
        ['COIN']: this.currencyFormat(this.generateReportCoin),
        ['NETTO SMS']:  this.currencyFormat(this.generateReportNetSms),
        ['SMS']: this.currencyFormat(this.generateReportSms),
        ['NETTO CARD']: this.currencyFormat(this.generateReportNetCard),
        ['CARD']:  this.currencyFormat(this.generateReportCard),
        ['NETTO CSC']: this.currencyFormat(this.generateReportNet),
        ['PDV CSC']: this.currencyFormat(this.generateReportPdv),
        ['LOYALTY']: this.currencyFormat(this.generateReportLoyalty),
        ['FINANCE TOTAL']: this.currencyFormat(this.generateReportCsc),
        ['TOKEN']: this.generateReportToken,
        ['TOTAL']:  ""
      })
    }
      this.$emit('data-sent', exportData);
    }
  },
  currencyFormat(num) {
      return num
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
  },
  watch: {
    isAdvancedView(newVal) {
      const isCompanyInParkingMode =
        this.currentUserCompanyModule[0] === 'parking'
      this.selectedColumns = isCompanyInParkingMode
        ? newVal
          ? [
              ...this.filteredColumns.filter((column) => column.isParkingMode),
              ...this.advancedViewColumns.filter(
                (column) => column.isParkingMode
              ),
            ].map((column) => column.name)
          : this.filteredColumns
              .filter((column) => column.isParkingMode)
              .map((column) => column.name)
        : newVal
        ? [
            ...this.selectedColumns,
            ...this.advancedViewColumns.map((column) => column.name),
          ]
        : this.filteredColumns
            .filter((column) => column.visible)
            .map((column) => column.name)
    },
  },
  created() {
    this.updateColumnNames()
    this.filteredColumns = this.columns.filter(
      (column) =>
        column.key !== 'deviceName' &&
        column.key !== 'location' &&
        column.key !== 'lessor'
    )

    const isCompanyInParkingMode =
      this.currentUserCompanyModule[0] === 'parking'

    if (isCompanyInParkingMode) {
      this.selectedColumns = [
        ...this.filteredColumns,
        ...this.advancedViewColumns,
      ]
        .filter((column) => column.visible && column.isParkingMode)
        .map((column) => column.name)
    } else {
      this.selectedColumns = [
        ...this.filteredColumns,
        ...this.advancedViewColumns,
      ]
        .filter((column) => column.visible)
        .map((column) => column.name)
    }
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--company-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;

      .vs__actions {
        padding: 14px;
      }
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
.table-container {
    height: 35vh;
    overflow-y: scroll;

    table {
      width: 100%;
    }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
</style>
